import React from "react"

export default function ContactStateLegislators() {
  return (
    <p>
      <strong>PLEASE CONTACT YOUR STATE LEGISLATORS</strong> - let them know how
      you feel! If you don't know who they are,{" "}
      <a
        href="http://findyourrep.legislature.ca.gov/"
        target="_blank"
        rel="noreferrer"
      >
        find your California Representative by clicking here
      </a>
      .
    </p>
  )
}
