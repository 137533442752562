import React from "react"
import SEO from "../components/seo"
import ContactStateLegislators from "../components/contact-state-legislators"
import Layout from "../components/layout"

export default function Contact() {
  return (
    <Layout>
      <SEO title="Contact" />
      <h1>Contact</h1>
      <p>
        Reach out to us on{" "}
        <a
          href="https://www.facebook.com/LocalControlCA/"
          target="_blank"
          rel="noreferrer"
        >
          Facebook
        </a>{" "}
        or send us an email at localcontrolca@gmail.com.
      </p>
      <ContactStateLegislators />
      <p>
        If you are currently a Mayor or Councilmember for a City in California,
        and you want to add your name to our supporter list (that is, you
        support our mission statement shown on the home page), then please email
        the following information to{" "}
        <a href="mailto:localcontrolca@gmail.com">localcontrolca@gmail.com</a>:
      </p>
      <ul className="ml-5 list-disc list-outside">
        <li>Name</li>
        <li>City</li>
        <li>Title</li>
        <li>Email Address</li>
        <li>Phone Number</li>
      </ul>
      <p>
        Please indicate if you will also recommend to your City to move forward
        with approving a resolution, and we can send you a template to assist
        with that.
      </p>
      <p>
        Once we receive your information, we will add you name to the list of
        supporters which will be shared publicly at our next supporter list
        update. We thank you in advance for adding your name to the list! Time
        is of the essence, as the State Legislators have already reconvened, and
        begun work on new bills that will impact us.
      </p>
    </Layout>
  )
}
